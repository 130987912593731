.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    padding-top: 12px;
}

.top-bar img {
    height: 30px
}

.help-text {
    padding: 3px;
    color: white;
    background: #cc0000;
    text-align: center;
}

.help-text a {
    color: white;
    font-weight: bold
}

.survey-title {
    padding: 3px;
    text-align: center;
}

@media (max-width:600px) {
    .help-text {
        font-size: 0.8rem;
    }
}

.content-area {
    width: 100%;
    flex-grow: 1;
    display: flex;
    background-color: white;
    /* overflow: hidden; */
}

div[data-ui-id="call-composite-survey"] + div {
    padding-bottom: 20px; /* Adjust the value as needed */
}

@media (max-width:786px) {
    .content-area button[data-ui-id="call-composite-start-call-button"]  {
        margin-bottom: 60px;
    }
}

button[data-ui-id="call-composite-start-call-button"] {
    min-width:150px;
}

button[data-ui-id="call-composite-start-call-button"] .ms-Button-label  {
    color: transparent;
    position: absolute;
    width: 100%;
}


button[data-ui-id="call-composite-start-call-button"] .ms-Button-label::before  {
    color: white;
    content: "Join Virtual Visit";
    display: block;
    position: absolute;
    width: 100%;
}

button[data-ui-id="call-composite-start-call-button"]:active .ms-Button-label::before  {
    color:  #0066CC;
}

button[data-ui-id="call-composite-start-call-button"]:hover .ms-Button-label::before {
    color: #004D99;
}

/*hide heading*/
svg[role='heading'] {
    height: 0px;
    visibility: hidden;
}

.ms-StackItem:has(> svg[role='heading']){
    height: 0px;
    visibility: hidden;
}

.thButton.help {
    width: auto;
    margin: 0px;
    margin-left: 10px;
    padding: 0 20px;
    min-height: 38px;
    font-weight: 600;
    font-family: 'CVSHealth';
    box-shadow:0 2px 4px rgba(0, 0, 0, 0.1);
}

[data-ui-id="call-composite-start-call-button"]:active > span {
    position: initial;

  }